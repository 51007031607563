import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import { Layout, SEO } from 'components';
import { builder } from 'utils/blocs';

export default ({ data: { data } }) => {
  const { title, seoWidget, pageBuilder } = data.page;

  return (
    <Layout siteTitle={title}>
      <SEO title={title} seoWidget={seoWidget} />
      {
        pageBuilder.content && pageBuilder.content.map(({ className, targetId, blocs }, idx) => (
          <div key={idx} id={targetId} className={className} data-sal='fade' data-sal-delay='50'>
            {blocs.map((bloc, blocId) => (
              <Fragment key={blocId}>
                {builder(bloc)}
              </Fragment>
            ))}
          </div>
        ))
      }
    </Layout>
  );
};

export const pageQuery = graphql`
  query PageQuery($id: ID!) {
    data {
      page(id: $id) {
        title
        seoWidget {
          title
          description
          keywords
          socialImage {
            mediaItemUrl
          }
        }
        pageBuilder {
          content {
            className
            targetId
            blocs {
              ...AccordionFragment
              ...AwardsGroupFragment
              ...CallToActionFragment
              ...ClinicFragment
              ...ContactFormFragment
              ...CoverFragment
              ...JumpTabFragment
              ...LinkedPagesFragment
              ...MagazineGroupFragment
              ...ParagraphFragment
              ...PictureFragment
              ...PressFragment
              ...PressGroupFragment
              ...ServicesGroupFragment
              ...TeamMemberFragment
              ...TitleFragment
            }
          }
        }
      }
    }
  }
`;
