import React, { Fragment } from 'react';
import {
  Accordion,
  AwardsGroup,
  CallToAction,
  Clinic,
  ContactForm,
  Cover,
  JumpTab,
  LinkedPages,
  MagazineGroup,
  Paragraph,
  Picture,
  Press,
  PressGroup,
  ServicesGroup,
  TeamMember,
  Title, 
} from 'components';

export const builder = (bloc) => {
  switch (bloc.__typename) {
  case 'WPGraphQL_Page_Pagebuilder_content_Blocs_Cover':
    return <Cover bloc={bloc} />;
  case 'WPGraphQL_Page_Pagebuilder_content_Blocs_Title':
    return <Title bloc={bloc} />;
  case 'WPGraphQL_Page_Pagebuilder_content_Blocs_Paragraph':
    return <Paragraph bloc={bloc} />;
  case 'WPGraphQL_Page_Pagebuilder_content_Blocs_Picture':
    return <Picture bloc={bloc} />;
  case 'WPGraphQL_Page_Pagebuilder_content_Blocs_CallToAction':
    return <CallToAction bloc={bloc} />;
  case 'WPGraphQL_Page_Pagebuilder_content_Blocs_ServicesGroup':
    return <ServicesGroup bloc={bloc} />;
  case 'WPGraphQL_Page_Pagebuilder_content_Blocs_AwardsGroup':
    return <AwardsGroup bloc={bloc} />;
  case 'WPGraphQL_Page_Pagebuilder_content_Blocs_PressGroup':
    return <PressGroup bloc={bloc} />;
  case 'WPGraphQL_Page_Pagebuilder_content_Blocs_MagazineGroup':
    return <MagazineGroup bloc={bloc} />;
  case 'WPGraphQL_Page_Pagebuilder_content_Blocs_LinkedPage':
    return <LinkedPages bloc={bloc} />;
  case 'WPGraphQL_Page_Pagebuilder_content_Blocs_TeamMember':
    return <TeamMember bloc={bloc} />;
  case 'WPGraphQL_Page_Pagebuilder_content_Blocs_Clinic':
    return <Clinic bloc={bloc} />;
  case 'WPGraphQL_Page_Pagebuilder_content_Blocs_Press':
    return <Press bloc={bloc} />;
  case 'WPGraphQL_Page_Pagebuilder_content_Blocs_JumpTab':
    return <JumpTab bloc={bloc} />;
  case 'WPGraphQL_Page_Pagebuilder_content_Blocs_Accordion':
    return <Accordion bloc={bloc} />;
  case 'WPGraphQL_Page_Pagebuilder_content_Blocs_ContactForm':
    return <ContactForm bloc={bloc} />;
  default:
    return <Fragment/>;
  }
}
